// 路由管理
import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import superData from "@/js/super";
import courtData from "@/js/court";
import managerData from "@/js/manager";

//使用router
Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


const routes = [
    {
        path: '/superLogin',
        name: '超级管理员',
        flag: 0,
        role: 1,
        component: () => import('@/views/SuperLogin')
    },
    {
        path: '/managerLogin',
        name: '管理人登录',
        flag: 0,
        role: 2,
        component: () => import('@/views/ManagerLogin')
    },
    {
        path: '/courtLogin',
        name: '法院登录',
        flag: 0,
        role: 3,
        component: () => import('@/views/JudgeLogin')
    },
    {
        path: '/',
        name: '刷新重置',
        flag: 0,
        role: 1,
        redirect: '/superLogin',
    },
    {
        path: '/vote',
        name: '投票页面',
        flag: 0,
        role: 1,
        component: () => import('@/views/dialog/Vote')
    },
    {
        path: '/sign',
        name: '签到页面',
        flag: 0,
        role: 1,
        component: () => import('@/views/dialog/Sign')
    },
    {
        path: '/meetingFile',
        name: '会议材料',
        flag: 0,
        role: 1,
        component: () => import('@/views/dialog/MeetingFile')
    },
];

// 将路由放到数组中
routes.push(...superData);
routes.push(...courtData);
routes.push(...managerData)


const router = new VueRouter({
    base: process.env.BASE_URL,
    routes: routes
})

// export const setRoutes = () => {
//     const storeMenus = localStorage.getItem("menus");
//     if (storeMenus) {
//         const menus = JSON.parse(storeMenus)
//         const menuRouter={
//             path:'/manage',
//             name:'后台页面',
//             item:'',
//             flag: true,
//             component:()=>import('../../../bankruptcy/src/view/Manage'),
//             children: []
//         }
//         menus.forEach(item => {
//             let itemMenu = {
//                 path: item.path,
//                 name: item.name,
//                 icon: item.icon,
//                 component: () => import('@/view/pages/'+item.pages)
//             }
//             menuRouter.children.push(itemMenu);
//         })
//         router.addRoute(menuRouter);
//     }
// }

//重置路由
// setRoutes()

// 登录拦截
router.beforeEach((to, from, next) => {
    let wPath={};
    wPath.path=to.path;
    wPath.name=to.name;
    sessionStorage.setItem("wPath", JSON.stringify(wPath))//设置当前的路由名称
    store.commit("setPath")//触发store的数据更新
    if (to.matched.length===0) {
        const user = sessionStorage.getItem("user")
        console.log(user)
        if (user) {
            next({ ...to, replace: true })
        } else {
            next("/managerLogin")
        }
    }else {
        next()
    }
})

export default router;